<template>
  <div>
    <!-- heads button -->
    <!-- <div class="heads">
      <el-button class="add" @click="AddCategory()">
        <i class="el-icon-plus"></i>
        {{$t('common.add')}}
      </el-button>
      <div class="clear"></div>
    </div> -->
    <!-- picture -->
    <el-card class="role">
      <el-row>
        <el-col :span="18" class="p-left">
          <span>{{ $t("brand.name") }}</span>
        </el-col>
        <el-col :span="4">
          <span>{{ $t("brand.time") }}</span>
        </el-col>
        <el-col :span="2">
          <span>{{ $t("common.operate") }}</span>
        </el-col>
      </el-row>
    </el-card>

    <el-card
      class="mt1 headquarters"
      v-for="(item, index) in data"
      :key="index"
    >
      <el-row>
        <el-col :span="18" class="mt1 p-left">
          <p>{{ $t("category." + item.category.categoryName) }}</p>
        </el-col>
        <el-col :span="4" class="mt1">
          <span>{{ item.createdAt }}</span>
        </el-col>
        <el-col :span="2" class="mt1" v-if="item.status == 1">
          <button class="p1" @click="edit(item._id)">
            {{ $t("common.edit") }}
          </button>
          <br />
          <button class="p2" @click="deletes(item._id)">
            {{ $t("common.delete") }}
          </button>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import {
  createData,
  getData,
  updateData,
  renderData,
  getLists,
} from "../../helper/api";
const moment = require("moment");

export default {
  data() {
    return {
      status: 0,
      data: [],
      editType: 0,
      store: {},
      ruleForm: {
        brandName: "",
        logo: "",
      },
      rules: {
        brandName: [
          {
            required: true,
            message: this.$t("brand.nameRequired"),
            trigger: "blur",
          },
        ],
        logo: [
          {
            required: true,
            message: this.$t("brand.logoRequired"),
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {
    this.getStore();
  },
  methods: {
    getStore() {
      renderData("GET", "store").then((res) => {
        this.store = res.data;
        if (this.store) {
          getLists("get", "storeCategory/" + res.data._id).then((res) => {
            // console.log(res);
            this.data = [];
            res.data.forEach((item) => {
              item.createdAt = moment(item.createdAt).format(
                "YYYY/MM/DD  HH:mm"
              );
              this.data.push(item);
            });
          });
        }
      });
    },
    doneData() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          if (this.editType == 1) {
            updateData("PUT", "brand", this.brandId, {
              img: this.ruleForm.logo,
              brandName: this.ruleForm.brandName,
            }).then((res) => {
              this.$message({
                message: res.success
                  ? this.$t("brand.brandUpdateSuccess")
                  : this.$t("brand." + res.message),
                type: res.success ? "success" : "error",
              });
            });
          } else {
            createData("POST", "brand", {
              img: this.ruleForm.logo,
              brandName: this.ruleForm.brandName,
            }).then((res) => {
              this.$message({
                message: res.success
                  ? this.$t("brand.brandCreateSuccess")
                  : this.$t("brand." + res.message),
                type: res.success ? "success" : "error",
              });
            });
          }

          this.brandList(this.currentPage);
        }
      });
    },
    addBrand() {
      this.editDialog = true;
      this.dialogTitle = this.$t("common.add");
      this.editType = 0;
      this.brandId = "";
      this.ruleForm.brandName = "";
      this.ruleForm.logo = "";
    },
    edit(id) {
      this.editDialog = true;
      this.dialogTitle = this.$t("common.edit");
      this.editType = 1;
      this.brandId = id;

      getData("GET", "brand", this.brandId).then((res) => {
        this.ruleForm.brandName = res.data.brandName;
        this.ruleForm.logo = res.data.img;
        this.logoUrl = res.data.img;
      });
    },
  },
};
</script>

<style scoped>
.heads .button {
  font-size: 13px;
  width: 105px;
  height: 38px;
  line-height: 18px;
  float: left;
}
.heads .button img {
  width: 17px;
  height: 17px;
  vertical-align: middle;
  margin-right: 2px;
  margin-left: -5px;
}
.heads .add {
  background: #ff0a0a;
  color: #fff;
  width: 120px;
  height: 40px;
  font-size: 14px;
  float: right;
  border-radius: 5px;
  border: none;
  margin-bottom: 10px;
}
.heads i {
  margin-right: 7px;
}
.role {
  background: #ffdcbc;
  font-size: 12px;
  font-weight: 700;
  text-align: center;
}
.role .picture {
  padding-left: 15px;
}
.headquarters {
  font-size: 12px;
  font-weight: 700;
  text-align: center;
}
.headquarters .delete {
  color: #ff0a0a;
}
.headquarters .p1 {
  color: #ff0a0a;
  background: none;
  cursor: pointer;
}
.headquarters .p2 {
  margin-top: 5px;
  color: gray;
  background: none;
  cursor: pointer;
}
.p-left {
  text-align: left;
}
.p-center {
  text-align: center;
}
.img {
  width: 40px;
  height: 40px;
  border-radius: 10px;
}
.heads-span {
  font-size: 16px;
}
p {
  font-size: 14px;
}
.dialog-footer {
  margin-top: 20px;
  text-align: right;
}
.ml {
  height: 60px;
  width: 60px;
  cursor: pointer;
}
</style>